<template>
  <v-card>
    <v-container fluid>
      <v-card-actions>
        <v-btn color="primary" @click="imprimir(manifesto.chave)"><v-icon>print</v-icon></v-btn>
      </v-card-actions>    
      <div id="manifesto" ref="manifesto">
        <div class="box">
          <div class="col-p-2 box no-border alinha-centro">
            <br/>
            <img src="../../img/mdfe.png" style="width: 120px; height: 120px;">
          </div>
          <div class="col-p-10 box no-border">
            <div class="row">
              <h1>{{manifesto.emissor.fantasia}}</h1>
            </div>
            <div class="row">
              <h3>CNPJ: {{ formatador.formatarCnpj(manifesto.emissor.cnpj)}} &nbsp;&nbsp; IE: {{manifesto.emissor.ie}}-{{manifesto.emissor.estado}}</h3>
            </div>
            <div class="row">
              <h3>Razão Social: {{manifesto.emissor.razao}}</h3>
            </div>
            <div class="row">
              <h3>Logradouro: {{manifesto.emissor.logradouro}} &nbsp;&nbsp; N. {{manifesto.emissor.numero}}</h3>
            </div>
            <div class="row">
              <h3>Complemento: {{manifesto.emissor.complemento}}</h3>
            </div>
            <div class="row">
              <h3>Bairro: {{manifesto.emissor.bairro}}</h3>
            </div>
            <div class="row">
              <h3>UF: {{manifesto.emissor.estado}} &nbsp;&nbsp; Município: {{manifesto.emissor.cidade}} &nbsp;&nbsp; CEP: {{ formatador.formatarCep(manifesto.emissor.cep)}}</h3>
            </div>
          </div>
        </div>

        <hr style="border: 0px; margin-top: 5px" />
        
        <div class="box">
          <div class="row">
            <div class="col-p-2 alinha-centro">
              <h1>DAMDFE</h1>
            </div>
            <div class="col-p-10">
              <h2>Documento Auxiliar de Manifesto Eletrônico de Documentos Fiscais</h2>
            </div>
          </div>
        </div>

        <hr style="border: 0px; margin-top: 2px" />
        
        <div class="box">
          <div class="row">
            <div class="col-p-12">
              <div class="titulo alinha-centro">
                CONTROLE DO FISCO
              </div>
              <div class="conteudo alinha-centro">
                <svg id="codigo_barras" class="fill-wh"></svg>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-12">
              <div class="campo-box bb0 bl0 br0">
                <div class="titulo">
                  CHAVE DE ACESSO
                </div>
                <div class="conteudo alinha-centro">
                  <h2>{{ formatador.formatarChave(manifesto.chave) }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-12">
              <div class="campo-box bb0 bl0 br0">
                <div class="titulo">
                  PROTOCOLO DE AUTORIZAÇÃO DE USO
                </div>
                <div class="conteudo alinha-centro">
                  <h2>{{ manifesto.autorizacao.protocolo }} {{ formatador.formatarDataHora(manifesto.autorizacao.data)}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr style="border: 0px; margin-top: 5px" />

        <!-- modelo, serie, numero, data, .... -->
        <div class="box">
          <div class="row">
            <div class="col-p-1">
              <div class="campo-box  bt0 bb0 bl0">
                <div class="titulo">
                  Modelo
                </div>
                <div class="conteudo">
                  {{manifesto.modelo}}
                </div>
              </div>
            </div>
            <div class="col-p-1">
              <div class="campo-box bt0 bb0 bl0">
                <div class="titulo">
                  Série
                </div>
                <div class="conteudo">
                  {{manifesto.serie}}
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0 bl0">
                <div class="titulo">
                  Número
                </div>
                <div class="conteudo">
                  {{manifesto.numero}}
                </div>
              </div>
            </div>
            <div class="col-p-1">
              <div class="campo-box bt0 bb0 bl0">
                <div class="titulo">
                  FL
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-3">
              <div class="campo-box bt0 bb0 bl0">
                <div class="titulo">
                  Data e Hora de Emissão
                </div>
                <div class="conteudo">
                  {{ formatador.formatarData(manifesto.data_emissao) }}
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0 bl0">
                <div class="titulo">
                  UF Carreg.
                </div>
                <div class="conteudo">
                  {{ manifesto.uf_origem }}
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box no-border">
                <div class="titulo">
                  UF Descar.
                </div>
                <div class="conteudo">
                  {{ manifesto.uf_destino }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr style="border: 0px; margin-top: 5px" />

        <div class="box">
          <div class="row">
            <div class="col-p-12">
              <div class="campo-box bl0 br0 bt0">
                <div class="conteudo alinha-centro">
                  <h3>Modal Rodoviário de Carga</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-2">
              <div class="campo-box no-border">
                <div class="titulo">
                  Qtd. CT-e
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0 br0">
                <div class="titulo">
                  Qtd. NF-e
                </div>
                <div class="conteudo">
                  {{manifesto.notas.length}}
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0 br0">
                <div class="titulo">
                  Peso Total (Kg)
                </div>
                <div class="conteudo">
                  {{ formatador.formatarValor(manifesto.peso_carga) }}
                </div>
              </div>
            </div>
            <div class="col-p-6">
              <div class="campo-box bt0 bb0 br0">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-7">
              <div class="campo-box no-mp bl0">
                <div class="conteudo">
                  <b>Veículo</b>
                </div>
              </div>
            </div>
            <div class="col-p-5">
              <div class="campo-box no-mp bl0 br0" style="margin: 0px -10px">
                <div class="conteudo">
                  <b>Condutor</b>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-3">
              <div class="campo-box no-mp bb0 bt0 bl0 br0">
                <div class="conteudo">
                  <b>Placa</b>
                </div>
              </div>
            </div>
            <div class="col-p-4">
              <div class="campo-box no-mp bb0 bt0">
                <div class="conteudo">
                  <b>RNTRC</b>
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box no-mp no-border">
                <div class="conteudo">
                  <b>CPF</b>
                </div>
              </div>
            </div>
            <div class="col-p-3">
              <div class="campo-box no-mp bt0 br0 bb0">
                <div class="conteudo">
                  <b>Nome</b>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-p-3">
              <div class="campo-box no-mp bl0 bb0 br0" style="margin: 0px -10px">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;&nbsp;&nbsp;
                  {{formatador.formatarPlaca(manifesto.veiculo.placa)}} &nbsp; {{manifesto.veiculo.uf}} 
                </div>
              </div>
            </div>
            <div class="col-p-4">
              <div class="campo-box no-mp bb0">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;&nbsp;&nbsp;
                  {{ manifesto.emissor.rntrc}}
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box no-mp bb0 bl0 br0" style="margin: 0px -10px">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;&nbsp;
                  {{ formatador.formatarCnpj(manifesto.condutor.cpf)}}
                </div>
              </div>
            </div>
            <div class="col-p-3">
              <div class="campo-box no-mp bb0 br0">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  {{manifesto.condutor.nome}}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-p-7">
              <div class="campo-box no-mp bl0">
                <div class="conteudo">
                  <b>Vale Pedágio</b>
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box no-mp bl0 bb0 br0 bt0" style="margin: 0px -10px">
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-3">
              <div class="campo-box no-mp br0 bb0 bt0">
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-p-3">
              <div class="campo-box no-border">
                <div class="titulo">
                  Responsável CNPJ
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0 br0">
                <div class="titulo">
                  Fornecedora CNPJ
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box bt0 bb0">
                <div class="titulo">
                  N° Comprovante
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-2">
              <div class="campo-box no-border">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col-p-3">
              <div class="campo-box bt0 bb0 br0">
                <div class="titulo">
                  &nbsp;
                </div>
                <div class="conteudo">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <hr style="border: 0px; margin-top: 5px" />
        
        <div class="box">
          <div class="col-p-12 no-mp">
            <div class="campo-box no-border">
              <div class="titulo">
                Observação
              </div>
              <div class="conteudo">
                &nbsp; <br/>
                &nbsp; <br/>
                &nbsp; <br/>
                &nbsp; <br/>
                &nbsp; <br/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </v-container>
  <div id="editor"></div>
  </v-card>
</template>

<script>

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {mapState} from "vuex";
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import conversor from '@/util/conversor';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';

export default {

  data: () => ({
      formatador: formatador,
      conversor: conversor
  }),

  computed: {

      ...mapState('manifesto', {
          manifesto: 'manifestoAtual'
      })
  },

  methods: {

    imprimir(chave){

      let conteudo = document.getElementById("manifesto");
      html2canvas(conteudo)
      .then((canvas) => {
        var pdf = new jsPDF('p', 'pt', 'letter');

        for (var i = 0; i <= conteudo.clientHeight/1335; i++) {
          var srcImg  = canvas;
          var sX      = 0;
          var sY      = 1335*i;
          var sWidth  = 1200;
          var sHeight = 1335;
          var dX      = 0;
          var dY      = 0;
          var dWidth  = 1200;
          var dHeight = 1335;

          window.onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', 1200);
          onePageCanvas.setAttribute('height', 1335);
          var ctx = onePageCanvas.getContext('2d');
          ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

          var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

          var width = onePageCanvas.width;
          var height = onePageCanvas.clientHeight;

          if (i > 0) {
              pdf.addPage(612, 791);
          }
          pdf.setPage(i+1);
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.50), (height*.62));
        }
        pdf.save('MDFe' + chave + '.pdf');

      }).catch((erro) => {
        throw erro;
      });
    },

    gerarCodigo(){
      JsBarcode('#codigo_barras', this.manifesto.chave, {displayValue: false, height: 40}); //format: 'CODE128'
    }
  },

  mounted(){
    this.gerarCodigo();
  }
}
</script>

<style lang="css" scoped>
    @import url('../../theme/impressoes.css');

</style>